
import { Component, Mixins } from 'vue-property-decorator';

import HeaderInfosMixin from '../header-infos';
import ConfigurationCollectionMixin from '@/mixins/data-operations/collection/configuration-collection-mixin';

import { apiToStorageConf } from '@/store/modules/easy-firestore/api-to-storage-conf';
import { API_TO_STORAGE_CONFIGURATIONS_ITEM } from '@/constants/router/routes-names';
import {
	ACTIVATED,
	CONF_CLIENT_VERSION,
	DESTINATIONS,
	ENVIRONMENT,
	ID,
	SOURCE_TYPE,
	TOPIC_SUFFIX,
} from '@/constants/data-operations/listing/header-items';

@Component
export default class StorageToStorageConfigurationsListingView extends Mixins(
	HeaderInfosMixin,
	ConfigurationCollectionMixin
) {
	moduleName: string = apiToStorageConf.moduleName;
	overriddenColumns: string[] = ['id', 'destinations'];

	get headers() {
		return [ENVIRONMENT, CONF_CLIENT_VERSION, ID, SOURCE_TYPE, TOPIC_SUFFIX, DESTINATIONS, ACTIVATED];
	}

	get routeName() {
		return API_TO_STORAGE_CONFIGURATIONS_ITEM;
	}
}
